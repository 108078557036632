<template>
  <div class="center">
    <topheader :avtive="activeIndex"></topheader>
    <div class="flex container base_w"> 
      <div class="leftnav">
        <p :class="{active: clickNavId == 1}" @click="clickNav(1)"> <span>用户协议</span> </p>
        <p :class="{active: clickNavId == 2}" @click="clickNav(2)"><span>隐私协议</span></p>
      </div>

      <div class="rightmain_center">
        <router-view />
      </div>
    </div>
   <!-- 页脚 -->
   <bottomfooter></bottomfooter>
  </div>
</template>

<script>
import topheader from '@/components/TopHeader.vue'
import bottomfooter from '@/components/Footer.vue'
// import loginUrl from '@/api/login'

export default {
  name: 'center',
  components: { topheader, bottomfooter },
  data() {
    return {
       activeIndex: -1,
       clickNavId: 1, // 1 用户协议 2 隐私协议
       hash:''
    };
  },
  watch:{
    $route() {
      this.int()
    },
  },
  mounted(){

    this.int()
    // 跳转回顶部
    document.documentElement.scrollTop = 0;

  },
 
  methods:{
    int(){
        this.hash = window.location.hash;
        if(this.hash.indexOf('yonghu') > -1){
          this.clickNavId = 1
        } else if(this.hash.indexOf('yinsi') > -1){
          this.clickNavId = 2
        } 
    },
    clickNav(num){
      this.hash = window.location.hash;
      this.clickNavId = num;

      if(num == 1){
        if(this.hash.indexOf('yonghu') > -1){
          return
        }
        this.$router.push({
          path:'/yonghu'
        })
      } else if(num == 2){
          if(this.hash.indexOf('yinsi') > -1){
            return
          }
          this.$router.push({
            path:'/yinsi'
          })
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.center{
  background: #F8F8F8;
  .container{
    margin-top: 20px;
    margin-bottom: 33px;
  }
// 左边
.leftnav{
  width: 200px;
  // min-height: 270px;
  height: 320px;
  background-color: #fff;
  color: #333333;
  text-align: center;
  p{
    height: 35px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 35px;
    padding: 17px 0;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    span{
      display: inline-block;
      width:100%;
    }
  }
  p.active{
    background-color: #fff;
    color: $colorMain;
    font-weight: 550;
    span{
      box-sizing: border-box;
      border-left: 5px solid  $colorMain;
    }
  }

}

// 右边
.rightmain_center{
  background-color: #fff;
  margin-left: 20px;
  box-sizing: border-box;
  width: 1216px;
}

}
</style>